// override clarity theme's  _variables.sass file.
// we recommend not editing this file directly. Instead, create an `assets/sass/override.sass` file at the root level of your site.
// if you edit this file directly, you will have to resolve git conflicts when and if you decide to pull changes we make on the theme

$font-path: '../fonts';
$icons: '../icons/';
$images: '../images/';
$light: #fff;
$haze: #fafafa;
$xhaze: darken($haze, 11%);
$bg: #303030;
$theme: #0060a0;
